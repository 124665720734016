.MuiTypography-body2 {
  font-family: "Sarabun", "Helvetica", "Arial", sans-serif !important;
}
.MuiDialogContent-root {
  padding-bottom: 10px !important;
}

a {
  color: black;
}
a:visited {
  color: black;
}
a:hover {
  color: cornflowerblue;
}
a:active {
  color: cornflowerblue;
}

code {
  font-family: inherit;
}

.MuiTableCell-root {
  white-space: nowrap;
}

.MuiInputBase-root {
  font-family: "Sarabun", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.dpk {
  margin-top: 10px;
  background-color: pink;
}

.rdt input.form-control {
  font-size: 16px !important;
  text-align: center;
  font-family: "Sarabun", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.MuiSelect-select.MuiSelect-select {
  font-size: 16px !important;
  padding: 10px 0 7px;
  font-family: "Sarabun", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.viewlist .MuiInput-root {
  margin-bottom: 20px;
}
.viewlist .MuiSelect-select.MuiSelect-select {
  padding: 6px 0 5px 0 !important;
}

.color-default {
  color: #999999;
}
.color-primary {
  color: #007bff;
}
.color-info {
  color: #17a2b8;
}
.color-success {
  color: #28a745;
}
.color-warning {
  color: #ffc107;
}
.color-danger {
  color: #dc3545;
}
.color-dark {
  color: #3c4858;
}
